import {
  fakeLoading,
  RenderData,
  displayLoader,
  openPopup,
} from "./assets/printLabel";
import axiosInstance from "../AxiosInstance";
import store from "../../redux/store";
import {
  setConfirm,
  setFromIndex,
  setOpen,
  setPrinted,
  setToIndex,
} from "../../redux/actions/initial";
const printSelected = async (selected, data, setError, setLoading) => {
  setLoading = setLoading ? setLoading : () => {};
  setError = setError ? setError : false;
  const instance = axiosInstance();
  store.dispatch(setPrinted(null));
  let shipmentsData;
  const date = {
    fromDate: new Date("2020-04-27T00:17:40.686Z").toDateString(),
    toDate: new Date().toDateString(),
  };
  if (data) {
    if (data.length > 0 || data.toString().length > 0) {
      if (typeof data === "number") {
        shipmentsData = {
          pickupNo: data,
          ...date,
        };
        setLoading(true);
        instance
          .post("/GetShipments", shipmentsData)
          .then((data) => {
            return data.data;
          })
          .then((data) => {
            const printQueue = (fromIndex = 0, toIndex = 100) => {
              if (fromIndex >= data.shipments.length) {
                return false;
              }
              if (toIndex > data.shipments.length) {
                toIndex = data.shipments.length;
              }

              displayLoader().then(async () => {
                const popUp = openPopup();
                popUp.onbeforeunload = () => {
                  store.dispatch(setConfirm(null));
                  store.dispatch(setOpen(true));
                  store.dispatch(setPrinted(fromIndex));
                  let currentValue;
                  function handleChange() {
                    let previousValue = currentValue;
                    currentValue = store.getState().initial.confirm;
                    if (previousValue !== currentValue) {
                      if (currentValue === true) {
                        fromIndex = toIndex;
                        toIndex += 100;
                        printQueue(fromIndex, toIndex);
                      } else if (currentValue === false) {
                        return false;
                      }
                    }
                  }
                  store.subscribe(handleChange);
                  const unsubscribe = store.subscribe(handleChange);
                  return unsubscribe;
                };
                fakeLoading(popUp).then(() => {
                  RenderData(
                    popUp,
                    {
                      ...data,
                      shipments: data.shipments.slice(fromIndex, toIndex),
                    },
                    selected
                  ).then(() => {
                    setTimeout(() => {
                      popUp.print();
                    }, 500);
                  });
                });
              });
            };
            printQueue();
          });
      } else {
        if (data.length >= 500) {
          const printQueue = (fromIndex = 0, toIndex = 100) => {
            let shouldprint = false;
            if (fromIndex === store.getState().initial.fromIndex) {
              return false;
            }
            if (fromIndex >= data.length) {
              return false;
            }
            if (toIndex > data.length) {
              toIndex = data.length;
            }
            store.dispatch(setFromIndex(fromIndex));
            store.dispatch(setToIndex(toIndex));
            instance
              .post("/GetShipments", {
                awBs: data.slice(fromIndex, toIndex).join(),
                ...date,
              })
              .then((data) => {
                return data.data;
              })
              .then((returnedData) => {
                displayLoader().then(async () => {
                  const popUp = openPopup();
                  popUp.onbeforeunload = () => {
                    store.dispatch(setConfirm(null));
                    store.dispatch(setOpen(true));
                    let currentValue;
                    function handleChange() {
                      let previousValue = currentValue;
                      currentValue = store.getState().initial.confirm;
                      if (previousValue !== currentValue) {
                        if (currentValue === true) {
                          fromIndex = toIndex;
                          toIndex += 100;
                          printQueue(fromIndex, toIndex);
                        } else if (currentValue === false) {
                          return false;
                        }
                      }
                    }
                    store.subscribe(handleChange);
                    const unsubscribe = store.subscribe(handleChange);
                    return unsubscribe;
                  };
                  fakeLoading(popUp).then(() => {
                    RenderData(popUp, returnedData, selected).then(() => {
                      setTimeout(() => {
                        popUp.print();
                        shouldprint = true;
                      });
                    });
                  });
                });
              });
          };
          printQueue();
        } else {
          const isOneShipment = typeof data === "string";
          const craftedData = {
            ...date,
            awBs: isOneShipment ? data : data.join(),
          };
          instance
            .post("/GetShipments", craftedData)
            .then((data) => {
              return data.data;
            })
            .then((data) => {
              displayLoader().then(() => {
                const popUp = openPopup();
                fakeLoading(popUp).then(() => {
                  RenderData(popUp, data, selected).then(() => {
                    setTimeout(() => {
                      popUp.print();
                    }, 500);
                  });
                });
              });
            });
        }
      }
    } else {
      return false;
    }
  }
};
export default printSelected;
