import { Zebra, Style2, Style3 } from "./templates";
const openPopup = () => {
  const popUpMenu = window.open("", "PRINT", "width=4000,height=4000");
  return popUpMenu;
};

const labels = new Map();
labels.set("style1", Zebra); // holds the Zebra label (letter)
labels.set("style2", Style2); // holds the style2 label (A4)
labels.set("style3", Style3); // holds the style3 label (10*10)

const loadCss = async (windowElement, selected) => {
  // check if the value of the select is stored in the labels map in order to deal with it
  if (labels.has(selected)) {
    const label = labels.get(selected);
    windowElement.document.head.innerHTML = `
            <title> Data Preview ${label.title} </title>
            <style>
                ${label.css()}
                @page{
                    size:${label.size};
                }
            </style>
        `;
  }
};
const RenderData = async (windowElement, data, selected) => {
  console.log("from render data ", data);
  const { shipments, courier } = data;
  loadCss(windowElement, selected).then(() => {
    // even faster
    // loading the data into fragment instead of adding it directly into the popUp window
    // slice data depending
    var fragment = new DocumentFragment();
    if (labels.has(selected)) {
      const label = labels.get(selected);
      shipments.forEach((shipment) => {
        fragment.appendChild(label.render(shipment, courier));
      });
      // adding the loaded fragment into the windowElement
      windowElement.document.body.innerHTML = "";
      windowElement.document.body.appendChild(fragment);
    }
  });
};
const displayLoader = async () => {
  const popUp = openPopup();
  popUp.document.body.innerHTML = `
        <div class="loading-indicator">
            <div class="loading-container">
                <div class="loading-circle">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-notch" class="svg-inline--fa fa-circle-notch fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>
                </div>
                <div class="loading-tracker">
                </div>
                <div class="loading-hint">
                    this may take a minute...
                </div>
            </div>
        </div>
    `;
  popUp.document.head.innerHTML = `
    <style>
        *{margin:0;padding:0;}.loading-indicator{position:fixed;width:100%;height:100%;display:grid;place-items:center;background:rgba(0,0,0,.2)}
        @keyframes spin {from {transform:rotate(0deg);}to {transform:rotate(360deg);}}
        .loading-circle .fa-circle-notch{font-size:86pt;animation-name: spin;animation-duration: 3000ms;animation-iteration-count: infinite;animation-timing-function: linear;}
        .loading-tracker{margin-top: 30px;font-size: 20pt;font-family: sans-serif;font-weight: bold;text-transform: capitalize}
        .loading-hint{text-align: center;margin-top: 15px;font-weight: bold;font-family: sans-serif;}
    </style>
    `;
};
// handles the fakeloader
const fakeLoading = async (windowElement) => {
  if (windowElement) {
    const loadingTracker =
      windowElement.document.querySelector(".loading-tracker");
    (function recursiveLoading(tmp) {
      if (tmp <= 100) {
        loadingTracker.innerHTML = `loading data [${tmp}% - 100%]`;
        setTimeout(() => {
          recursiveLoading(tmp + 10);
        }, 10);
      }
    })(0);
  }
};
export { fakeLoading, RenderData, displayLoader, openPopup };
