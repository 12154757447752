import React from "react";
import { CircularProgress, Modal } from "@mui/material";
import styled from "styled-components";
import axiosInstance from "../helpers/AxiosInstance";
import { useState } from "react";
import fileDownload from "js-file-download";
import { useSelector } from "react-redux";
import { Button } from "./utilities";
const Card = styled.div`
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  padding: 3rem;
  height: 75vh;
  max-width: 80vw;

  justify-content: center;
`;
const LabelImage = styled.img`
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ExitButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0;
  width: 40px;
  height: 40px;
  border: 0;
  background: transparent
    linear-gradient(
      180deg,
      var(--primary-color) 0%,
      var(--secondary-color) 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 0px 10px 0px 0px;
  opacity: 1;
  font-size: 25px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #ffffff;
`;
export default function LabelPreview(props) {
  const { clientName } = useSelector((state) => ({
    clientName: state.Auth.AuthedUser.ClientName,
  }));
  const { open, setOpen, label, data } = props;
  const [loading, setLoading] = useState(false);
  const instance = axiosInstance();
  const handlePrintPdf = async () => {
    let craftedData = {
      awBs: [],
    };
    if (typeof data === "number") {
      craftedData.pickupNo = data;
      craftedData.awBs = [];
    } else {
      craftedData.awBs = typeof data === "string" ? [data] : data;
      delete craftedData.pickupNo;
    }
    setLoading(true);
    const response = await instance
      .post(
        "ExportPDF",
        {
          templateID: label.templateID,
          ...craftedData,
        },
        { responseType: "blob" }
      )

      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
    setOpen(false);
    console.log(response);
    fileDownload(
      response.data,
      `${clientName} - ${new Date().toDateString()}.pdf`
    );
  };
  console.log("data", data);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <ExitButton onClick={() => setOpen(false)}>X</ExitButton>
        {label ? (
          <>
            <LabelImage src={`/labels/${label.name}.png`} alt="Label Image" />
            <Button onClick={handlePrintPdf} disabled={loading}>
              {loading ? <>loading ...</> : <>Export PDF </>}
            </Button>
          </>
        ) : (
          <>
            <h3>No Recorded Image</h3>
            <LabelImage
              src={`/labels/${label?.name}.png`}
              alt="image not found"
            />
          </>
        )}
      </Card>
    </Modal>
  );
}
