import * as React from "react";
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
export default function DeprecationDialog({ open, setOpen, callback }) {
  const { t } = useTranslation("dialogs");
  const { args, fn } = callback;
  const handleClose = () => {
    setOpen(false);
  };
  const handleAccept = () => {
    fn.apply(null, args);
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("deprecation.message_header")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("deprecation.message_body")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t("deprecation.message_close")}
          </Button>
          <Button onClick={handleAccept} autoFocus>
            {t("deprecation.message_accept")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
